<template>
  <div class="iframe-container-box">
    <div class="searchForm">
      <renderForm :formData.sync="searchForm" :list="ruleForm" ref="ruleFormRef" labelWidth="70">
        <template v-slot:dataPick>
          <rs-form-item label="操作时间">
            <rs-date-picker v-model="searchForm.operateTime" value-format="yyyy/MM/dd HH:mm:ss" type="datetimerange"
              align="right" style="width: 150%;" start-placeholder="开始日期" end-placeholder="结束日期" :clearable="false">
            </rs-date-picker>
          </rs-form-item>
        </template>
        <template v-slot:operation>
          <rs-button type="primary" size="small" @click="handleSearch">查询</rs-button>
          <rs-button type="text" @click="clear">重置</rs-button>
        </template>
      </renderForm>
    </div>
    <!-- 表格 -->
    <div class="resultContent">
      <renderTable height="calc(100vh - 200px)" highlight-current-row class="my-table" :list="tableData"
        :columns="tableColumns" border>
        <div slot="action">
          <rs-button type="primary" size="small" @click="logout">导出</rs-button>
        </div>
      </renderTable>
    </div>
    <rs-pagination style="margin-top: 12px" @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page="pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" background
      layout="prev, pager, next, sizes, jumper" :total="total"></rs-pagination>
  </div>
</template>
<script>
  import Utils from '@/utils/utils'
  import API from '@/api/api'
  let tableColumns = [{
      width: 70,
      type: 'index',
      fixed: true,
      label: '序号',
      align: 'left'
    },
    {
      prop: 'loginName',
      label: '登录名',
      maxWidth: '80px',
      align: 'left',
      width: 120
    },
    {
      prop: 'loginSource',
      label: '登录来源',
      maxWidth: '80px',
      align: 'left',
      width: 120
    },
    {
      prop: 'operateContent',
      label: '操作内容',
      maxWidth: '80px',
      align: 'left',
      width: 100
    },
    {
      prop: 'operateDetail',
      label: '操作详情',
      maxWidth: '80px',
      align: 'left',
      width: 200
    },
    {
      prop: 'remark',
      label: '结果',
      maxWidth: '80px',
      align: 'left',
      width: 100
    },
    {
      prop: 'operateTime',
      label: '操作时间',
      maxWidth: '80px',
      align: 'left',
      width: 200
    },
    {
      prop: 'ipAddress',
      label: 'ip地址',
      maxWidth: '80px',
      align: 'left',
      width: 230
    },
    {
      prop: 'country',
      label: '国家',
      maxWidth: '80px',
      align: 'left',
      width: 100
    },
    {
      prop: 'languages',
      label: '语言',
      maxWidth: '80px',
      align: 'left',
      width: 100
    },
    {
      prop: 'deviceType',
      label: '设备类型',
      maxWidth: '80px',
      align: 'left',
      width: 100
    },
    {
      prop: 'os',
      label: '操作系统',
      maxWidth: '80px',
      align: 'left',
      width: 100
    },
    {
      prop: 'osVersion',
      label: '系统版本',
      maxWidth: '80px',
      align: 'left',
      width: 100
    },
    {
      prop: 'browser',
      label: '浏览器',
      maxWidth: '80px',
      align: 'left',
      width: 100
    },
    {
      prop: 'browserVersion',
      label: '浏览器版本',
      maxWidth: '80px',
      align: 'left',
      width: 130
    },
    {
      prop: 'pageUrl',
      label: '被访问页面的url',
      maxWidth: '80px',
      align: 'left'
    }
  ]
  let ruleForm = [{
      type: 'input',
      label: '登录名',
      key: 'loginName'
    },
    {
      type: 'input',
      label: '操作详情',
      key: 'operateDetail'
    },
    // {
    //   type: 'datetime',
    //   label: '开始时间',
    //   'value-format': 'yyyy/MM/dd HH:mm:ss',
    //   key: 'operateTimeStart'
    // },
    // {
    //   type: 'datetime',
    //   label: '结束时间',
    //   'value-format': 'yyyy/MM/dd HH:mm:ss',
    //   key: 'operateTimeEnd'
    // },
    {
      slot: 'dataPick'
    },
    {
      slot: 'operation'
    }
  ]

  export default {
    data() {
      return {
        tableData: [],
        pageSize: 10,
        total: 0,
        pageNum: 1,
        tableColumns: tableColumns,
        searchForm: {
          loginName: '',
          operateDetail: '',
          operateTimeStart: '',
          operateTimeEnd: '',
          operateTime: '',
          signDate: []
        },
        ruleForm: ruleForm
      }
    },
    created() {
      this.searchForm.operateTime = this.operateTimeArr()
      this.getData()
    },
    methods: {
      // 默认时间
      operateTimeArr() {
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth();
        let currentMonth = date.getMonth() + 1;
        let day = date.getDate();
        if (month < 10) {
          month = '0' + month
        }
        if (currentMonth < 10) {
          currentMonth = '0' + currentMonth
        }
        if (day < 10) {
          day = '0' + day
        }
        let startTime = `${year}/${month}/${day} 00:00:00`
        let timeEnd = `${year}/${currentMonth}/${day} 23:59:59`
        if (month == 12) {
          timeEnd = `${year+1}/01/${day} 23:59:59`
        }
        return [startTime, timeEnd]
      },
      async getData() {
        let params = {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          ...this.queryparams()
        }
        let data = await API.querySysLogList(params)
        this.tableData = data.data.data.list
        this.total = data.data.data.total
      },
      queryparams() {
        let {
          userName,
          userCode
        } = Utils.getSession('userInfo')
        return {
          operateCode: userCode,
          operateName: userName,
          loginName: this.searchForm.loginName,
          operateDetail: this.searchForm.operateDetail,
          operateTimeStart: this.searchForm.operateTime[0] || '',
          operateTimeEnd: this.searchForm.operateTime[1] || ''
        }
      },
      handleSizeChange(val) {
        this.pageSize = val
        this.getData()
      },
      handleCurrentChange(val) {
        this.pageNum = val
        this.getData()
      },
      handleSearch() {
        this.pageNum = 1
        this.getData()
      },
      clear() {
        this.searchForm.loginName = ''
        this.searchForm.operateDetail = ''
        this.searchForm.operateTimeStart = ''
        this.searchForm.operateTimeEnd = ''
      },
      logout() {
        if (this.tableData.length == 0) this.$message.error('不能导出空数据')
        else
          API.exportSysLog(this.queryparams()).then(res => {
            this.$message.success('导出成功，请至下载中心下载！')
          })
      }
    }
  }
</script>
<style lang="less" scoped>
  /deep/ .rs-col.rs-col-6.operation {
    width: 200px;
    margin-left: 10% !important;
  }
</style>